























































































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";
import TagColorInput from "@/components/TagColorInput.vue";
import TagColorDisplay from "@/components/TagColorDisplay.vue";

export default Vue.extend({
  components: { TagColorInput, TagColorDisplay },
  data() {
    return {
      userManager,
      dialog: false,
      form: false,
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      headers: [
        { text: this.$t("PACKAGE_ID"), value: "package_id" },
        { text: "Nhập từ đơn", value: "virtual_package_id" },
        { text: this.$t("TRUCK_ID"), value: "truck_id" },
        {
          text: this.$t("USER_NAME"),
          value: "sale_name",
          sortable: false,
        },
        { text: this.$t("NAME"), value: "name" },
        { text: this.$t("WEIGHT"), value: "weight" },
        { text: this.$t("VOLUME"), value: "volume" },
        { text: "Nợ tiền hàng", value: "debt" },
        { text: "Lợi nhuận thanh toán", value: "profit" },
        { text: "Giá khối", value: "fee_per_volume" },
        { text: "Giá cân", value: "fee_per_weight" },
        { text: "Tiền vận chuyển", value: "fee_transport" },
        { text: "Ủy thác", value: "fee_trust" },
        { text: "Phí khác", value: "fee_other" },
        { text: "Chính sách ", value: "policy_money" },
        { text: "Ghi chú", value: "money_note" },
        { text: "Gợi ý", value: "suggest" },
        { text: "Màu tag", value: "tag_color" },
        (!userManager.getImpersonateUserInfo() || userManager.checkRole(["gdkd"])) && {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false,
        },
      ],
      options: {},
      search: "",
      searchTagColor: null,
      filterTruckId: undefined,
      loading: false,
      data: [],
      dataCount: 0,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      applyVirtualPackageDialog: false,
      applyVirtualPackageForm: false,
      applyVirtualPackageDialogId: "",
      applyVirtualPackageItem: null,
      applyVirtualPackageDialogItems: [],
      applyVirtualPackageDialogSearch: "",
      applyVirtualPackageDialogReady: false,
      exportedFields: [
        "package_id",
        "virtual_package_id",
        "truck_id",
        "sale_name",
        "name",
        "weight",
        "volume",
        "debt",
        "profit",
        "fee_per_volume",
        "fee_per_weight",
        "fee_transport",
        "fee_trust",
        "fee_other",
        "money_note",
      ],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t("CREATE_PACKAGE") : this.$t("EDIT");
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    applyVirtualPackageDialogSearch: {
      async handler() {
        this.doSearchVirtualPackages();
      },
    },
    // async applyVirtualPackageDialog(val) {
    //   if (!val) {
    //     this.applyVirtualPackageDialogReady = false;
    //     return;
    //   }
    //   const arr = this.applyVirtualPackageItem.package_id.split("/");
    //   const search = arr.slice(0, 2).join("/");
    //   const items = await this.searchVirtualPackages(search, 1);
    //   if (items && items.length) {
    //     this.applyVirtualPackageDialogId = items[0];
    //   } else {
    //     this.applyVirtualPackageDialogId = "";
    //   }
    //   this.applyVirtualPackageDialogReady = true;
    //   this.doSearchVirtualPackages();
    // },
  },
  methods: {
    async initialize() {
      this.loading = true;
      const filters = [
        {
          key: "package_id",
          operator: "match",
          value: this.search,
        },
        {
          key: "truck_id",
          operator: ">",
          value: 80,
        },
        {
          key: "tag_color",
          operator: "equal_to",
          value: this.searchTagColor,
        },
      ];
      if (this.filterTruckId) {
        filters.push({
          key: "truck_id",
          operator: "equal_to",
          value: this.filterTruckId,
        });
      }
      const { items, count } = await apiClient.packageGetAll({
        options: this.options,
        filters,
        extra: {
          register: true,
          money: true,
        },
      });
      this.data = items;
      this.dataCount = count;
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.fee_per_volume = this.editedItem.fee_per_volume_overridden || this.editedItem.fee_per_volume;
      this.editedItem.fee_per_weight = this.editedItem.fee_per_weight_overridden || this.editedItem.fee_per_weight;
      this.editedItem.fee_trust = this.editedItem.fee_trust_overridden || this.editedItem.fee_trust;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      const originItem = this.data[this.editedIndex];
      const updatedItem: any = {
        id: this.editedItem.id,
        fee_other: this.editedItem.fee_other,
      };
      const fields = ["fee_other"];
      if (this.userManager.checkRole(["gdkd"])) {
        if (this.editedItem.fee_per_weight !== originItem.fee_per_weight) {
          updatedItem.fee_per_weight_overridden = this.editedItem.fee_per_weight;
          fields.push("fee_per_weight_overridden");
        }
        if (this.editedItem.fee_per_volume !== originItem.fee_per_volume) {
          updatedItem.fee_per_volume_overridden = this.editedItem.fee_per_volume;
          fields.push("fee_per_volume_overridden");
        }
        if (this.editedItem.fee_trust !== originItem.fee_trust) {
          updatedItem.fee_trust_overridden = this.editedItem.fee_trust;
          fields.push("fee_trust_overridden");
        }
      }
      const result = await apiClient.packageUpdate(updatedItem, fields);
      if (result) {
        this.close();
        await this.initialize();
      }
    },
    async searchVirtualPackages(search, limit = 15) {
      const { items } = await apiClient.virtualPackageGetAll({
        filters: [
          {
            key: "virtual_package_id",
            operator: "match",
            value: search,
          },
        ],
        rawOptions: {
          limit,
        },
      });
      return items.map((item) => item.virtual_package_id);
    },
    async doSearchVirtualPackages() {
      const items = await this.searchVirtualPackages(this.applyVirtualPackageDialogSearch);
      if (this.applyVirtualPackageDialogId) {
        if (items.indexOf(this.applyVirtualPackageDialogId) === -1) {
          items.unshift(this.applyVirtualPackageDialogId);
        }
      }
      this.applyVirtualPackageDialogItems = items;
    },
    async applyVirtualPackage() {
      if (
        await apiClient.packageApplyVirtualPackage(this.applyVirtualPackageItem.id, this.applyVirtualPackageDialogId)
      ) {
        this.applyVirtualPackageDialogId = "";
        this.applyVirtualPackageItem = null;
        this.applyVirtualPackageDialog = false;
        await this.initialize();
      }
    },
    async openApplyVirtualPackageDialog(item, virtualPackageId = "") {
      this.applyVirtualPackageDialogId = virtualPackageId;
      this.applyVirtualPackageItem = item;
      this.applyVirtualPackageDialog = true;
    },
    async exportAll() {
      const url = apiClient.packageExport(this.exportedFields);
      location.replace(url);
    },
    async exportByTruck(truckId) {
      const url = apiClient.packageExport(this.exportedFields, null, truckId);
      location.replace(url);
    },
  },
});
