



































import Vue from "vue";
export default Vue.extend({
  props: ["value", "label"],
  data() {
    return {
      colors: ["red", "blue", "green", "violet", "yellow", "grey", ""],
    };
  },
  computed: {
    items() {
      return this.colors.map((item) => {
        return {
          value: item,
        };
      });
    },
  },
});
